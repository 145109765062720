<!-- 计算器结果页 -->
<template>
  <div class="CalculatorResult">
    <div class="item" v-for="item in source" :key="item.code">
      <div class="left">{{ item.name }}</div>
      <div
        class="right"
        v-if="
          item.name == '项目' ||
            item.name == '首付比例' ||
            item.name == '年化利率'
        "
      >
        {{ item.value ? item.value + item.unit : "" }}
      </div>
      <div class="right" v-else>
        {{ item.value ? Math.round(item.value) + item.unit : "" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculatorResult",
  data() {
    return {
      source: [
        { name: "项目", value: "方案", unit: "" },
        { name: "总融资额", code: "beginningPrincipal", unit: "元" },
        { name: "首付比例", code: "beginRatio", unit: "" },
        { name: "融资期数", code: "period", unit: "期" },
        { name: "年化利率", code: "interestRate", unit: "" },
        { name: "月租金", code: "monthlyAmount", unit: "元" },
        { name: "月利息", code: "paidInterestAmount", unit: "元" },
        { name: "总利息", code: "paidInterestAmountSum", unit: "元" }
      ]
    };
  },
  created() {
    const result = this.$route.query;
    this.source.forEach(item => {
      if (item.name != "项目") {
        item.value = result[item.code];
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.CalculatorResult {
  height: 100%;
  background: #ffffff;
  .item {
    line-height: 0.44rem;
    border-bottom: 1px solid #f1f2f6;
    &:nth-child(1) {
      font-weight: bold;
    }
    .left,
    .right {
      display: inline-block;
      padding: 0 0.2rem;
      width: 50%;
      font-size: 0.13rem;
      color: #090932;
    }
    .right {
      border-left: 1px solid #f1f2f6;
    }
  }
}
</style>
